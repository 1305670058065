import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className='footer'>
      <p>© 2023 All Rights Reserved</p>
    </footer>
  );
};

export default Footer;
