import "./about.css";
import salas from "../../img/salas.png";

const About = () => {
  return (
    <div className='a'>
      <div className='a-left'>
        <div className='a-card bg'></div>
        <div className='a-card'>
          <img src={salas} alt='' className='a-img' />
        </div>
      </div>
      <div className='a-right'>
        <h1 className='a-title'>About Me</h1>
        <p className='a-desc'>
          I'm a 2-year full-stack developer student, excited to develop secure
          and good-looking solutions.
          <br />
          <br /> My skills include:
          <ul>
            <li>React, Javascript, Express.js</li>
            <li>Node, MongoDB, Docker, SQL</li>
            <li>TypeScript, TRPC, Prisma, CSS, HTML</li>
          </ul>
          <br />I also have experience with other technologies.
        </p>
        <div className='a-award'>
          <div className='a-award-texts'>
            <h2 className='a-award-title'>My Approach</h2>

            <p className='a-award-desc '>
              I enjoy solving problems and learning new technologies to enhance
              my development skills. I am highly motivated, diligent, and never
              give up when faced with challenges. I thrive in team environments
              but also work well independently. Here you can see&nbsp;my&nbsp;
              <a
                href='https://www.linkedin.com/your-linkedin-profile'
                target='_blank'
                rel='noopener noreferrer'
                class='a-link'
              >
                LinkedIn profile
              </a>
              , and here you can find my&nbsp;
              <a
                href='https://github.com/your-github-username'
                target='_blank'
                rel='noopener noreferrer'
                class='a-link'
              >
                GitHub
              </a>
              .
            </p>

            <p className='a-award-desc '>
              I am proficient in communication, love spreading positive energy,
              and enjoy sharing laughter.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
