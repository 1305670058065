import CINEMAH from "../src/img/CINEMAH.png";
import Recipet from "../src/img/Recipet.png";
import WEATHER from "../src/img/WEATHER.png";
import QR from "../src/img/QR.png";
import JOKESITE from "../src/img/JOKESITE.png";

export const products = [
  // {
  //   id: 1,
  //   img: CINEMAH,
  //   link: "https://github.com/NaeliaSalas/BE_U09",
  // },
  {
    id: 2,
    img: Recipet,
    link: "https://nimble-marshmallow-3c8cb3.netlify.app/",
    description:
      "Recipe app project for school assigment, search and learn a new recipe today!",
  },
  {
    id: 3,
    img: WEATHER,
    link: "https://relaxed-toffee-bd9b76.netlify.app/",
    description: "Weather app to see the weather of your location.",
  },
  {
    id: 4,
    img: QR,
    link: "https://main--comfy-cendol-5216ff.netlify.app/",
    description: "Generate a new QR code for any website need.",
  },
  {
    id: 5,
    img: JOKESITE,
    link: "https://charming-kringle-f7c412.netlify.app/",
    description:
      "Fullstack project made in school, create a account and send in funny stories and share with other users.",
  },
  // {
  //     id: 6,
  //     img: CINEMAH,
  //     link: "http://lama.dev"
  // }
];
